/* global document */

function getCookie(name) {
	function escape(s) {
		return s.replace(/([.*+?$(){}|\\])/g, '\\$1');
	}
	const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));
	return match ? match[1] : null;
}

function setCookie(name, value, days) {
	const date = new Date();
	date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
	const expires = `expires=${date.toUTCString()}`;
	document.cookie = `${name}=${value}; path=/; ${expires}`;
}

function showOffer(promoModal) {
	const currentDate = new Date();
	const currentDateString = currentDate.toISOString().slice(0, 10); // Get YYYY-MM-DD format

	const lastShownDate = getCookie('plakatov_promo_offer_date');
	const hasSeenToday = lastShownDate === currentDateString;
	const isWithinDateRange =		(currentDate.getMonth() === 11 && currentDate.getDate() >= 20)
		|| (currentDate.getMonth() === 0 && currentDate.getDate() <= 5);

	if (!hasSeenToday && isWithinDateRange) {
		promoModal.classList.add('is-open');
		setCookie('plakatov_promo_offer_date', currentDateString, 1); // cookie to expire in 1 day
	}
}

document.addEventListener('DOMContentLoaded', () => {
	const promoModal = document.querySelector('.js-promo-offer');

	if (promoModal) {
		const exits = promoModal.querySelectorAll('[data-dismiss]');

		if (exits.length > 0) {
			exits.forEach((e) => {
				e.addEventListener('click', (event) => {
					event.preventDefault();
					promoModal.classList.remove('is-open');
				});
			});
		}

		showOffer(promoModal);
	}
});
