/* global document */
import naja from 'naja';

let aresButton;
let icInput;

export default function init() {
	aresButton = document.querySelector('.js-ares-button');
	icInput = document.querySelector('.js-ares-input');

	if (aresButton && icInput) {
		aresButton.addEventListener('click', () => {
			const hasValue = icInput.value !== '';
			if (hasValue) {
				aresButton.classList.add('is-loading');
				aresButton.disabled = true;
			}
		});
	}
}

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--aresLoadedData' || snippet.id === 'snippet--aresLoadedDataCompany') {
		if (aresButton) {
			aresButton.classList.remove('is-loading');
			aresButton.disabled = false;
		}
	}

	init();
});

init();
