/* global document */
import naja from 'naja';

function initFlashes() {
	const flashes = document.querySelectorAll('.js-flash');

	function flashFade(flash) {
		flash.classList.remove('flash--visible');
		setTimeout(() => {
			flash.remove();
		}, 200);
	}

	flashes.forEach((flash) => {
		setTimeout(() => {
			flashFade(flash);
		}, 4500);

		const exits = flash.querySelectorAll('.js-flash-close');
		if (exits) {
			exits.forEach((exit) => {
				exit.addEventListener('click', (event) => {
					event.preventDefault();
					flashFade(flash);
				});
			});
		}
	});
}

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--flashes') {
		initFlashes();
	}
});

initFlashes();
