/* global document, window */

const playBtn = document.querySelector('.js-video-play');
const videoBanner = document.querySelector('.js-video-banner');
const videoWrapperDesktop = document.querySelector('.js-video-wrapper-desktop');
const videoWrapperMobile = document.querySelector('.js-video-wrapper-mobile');
const videoItemDesktop = document.querySelector('.js-video-item-desktop');
const videoItemMobile = document.querySelector('.js-video-item-mobile');

function playVideo(event) {
	event.preventDefault();
	videoBanner.classList.add('switch');
	if (window.innerWidth > 576) {
		videoWrapperDesktop.classList.add('switch');
		videoItemDesktop.play();
	} else {
		videoWrapperMobile.classList.add('switch');
		videoItemMobile.play();
	}
}

if (playBtn) {
	playBtn.addEventListener('click', playVideo);
}

const videoBlocks	= document.querySelectorAll('.js-video-block');

videoBlocks.forEach((videoBlock) => {
	const videoPlayBtn = videoBlock.querySelector('.js-video-play-btn');
	const video = videoBlock.querySelector('.js-video');
	const videoOverlay = videoBlock.querySelector('.js-video-overlay');
	videoPlayBtn.addEventListener('click', () => {
		videoOverlay.classList.add('is-hidden');
		videoPlayBtn.classList.add('is-hidden');
		video.play();
	});
});
