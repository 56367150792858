/* global document navigator */
import naja from 'naja';

function init() {
	const copyBtns = document.querySelectorAll('.js-copy');

	copyBtns.forEach((copyBtn) => {
		copyBtn.addEventListener('click', () => {
			navigator.clipboard.writeText(copyBtn.dataset.copy).then(
				() => {
					naja.makeRequest('GET', copyBtn.dataset.urlPass, null, { history: false });
				},
				() => {
					naja.makeRequest('GET', copyBtn.dataset.urlFail, null, { history: false });
				},
			);
		});
	});
}

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--cartContent') {
		init();
	}
});

init();
