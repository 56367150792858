/* global document, window */

function renderRecaptcha() {
	const { grecaptcha } = window;
	const items = document.getElementsByClassName('g-recaptcha');

	if (items.length > 0) {
		grecaptcha.ready(() => {
			for (let i = 0; i < items.length; i += 1) {
				grecaptcha.render(items[i]);
			}

			grecaptcha.execute().then((token) => {
				const inputs = document.getElementsByClassName('g-recaptcha-response');
				for (let i = 0; i < items.length; i += 1) {
					inputs[i].value = token;
				}
			});
		});
	}
}

const recaptchaScript = document.createElement('script');
recaptchaScript.async = true;
recaptchaScript.defer = true;
recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
recaptchaScript.addEventListener('load', renderRecaptcha);
window.document.body.appendChild(recaptchaScript);
