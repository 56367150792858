/* global document */

const stickers = document.querySelectorAll('.js-hero__sticker');
let currentIndex = 0;

if (stickers.length !== 0) {
	setInterval(() => {
		stickers[currentIndex].classList.remove('is-sticker-active');
		currentIndex = (currentIndex + 1) % stickers.length;
		stickers[currentIndex].classList.add('is-sticker-active');
	}, 2500);
}
