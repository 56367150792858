/* global document */

const select = document.querySelector('.js-types__select');
const items = document.querySelectorAll('.js-types__item');

if (select) {
	select.addEventListener('change', () => {
		const selectedValue = select.value;
		items.forEach((item) => {
			if (item.dataset.target === selectedValue) {
				setTimeout(() => {
					item.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}, 0);
			}
		});
	});
}
