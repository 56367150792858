/* global document */
import naja from 'naja';

let dropdowns = document.querySelectorAll('.js-dropdown');

const onClick = (event) => {
	event.preventDefault();
	const { hash } = event.currentTarget;
	const targetEl = document.querySelector(hash);
	if (targetEl) {
		targetEl.classList.toggle('show');
	}
};

function init() {
	const dropdownTriggers = document.querySelectorAll('.js-dropdown-trigger');
	dropdowns = document.querySelectorAll('.js-dropdown');

	if (dropdownTriggers) {
		dropdownTriggers.forEach((drop) => {
			drop.removeEventListener('click', onClick);
			drop.addEventListener('click', onClick);
		});
	}
}

document.addEventListener('click', (e) => {
	if (
		!e.target.closest('.js-dropdown-trigger')
		&& !e.target.closest('.js-dropdown')
	) {
		dropdowns.forEach((drop) => drop.classList.remove('show'));
	}
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--adPlaceList') {
		init();
	}
});

init();
