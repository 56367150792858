/* global window, document */

window.addEventListener('load', () => {
	const { gtm } = document.documentElement.dataset;
	if (gtm) {
		(function initializeGTM(w, d, s, l, i) {
			// eslint-disable-next-line no-param-reassign
			w[l] = w[l] || [];
			w[l].push({
				'gtm.start':
					new Date().getTime(),
				event: 'gtm.js',
			});
			const f = d.getElementsByTagName(s)[0];
			const j = d.createElement(s);
			const dl = l !== 'dataLayer' ? `&l=${l}` : '';
			j.async = true;
			j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
			f.parentNode.insertBefore(j, f);
		}(window, document, 'script', 'dataLayer', gtm));
	}
	const event = new Event('GTMload');
	document.dispatchEvent(event);
});

function waitForFbq(callback) {
	if (typeof fbq !== 'undefined') {
		callback();
	} else {
		setTimeout(() => {
			waitForFbq(callback);
		}, 100);
	}
}

waitForFbq(() => {
	const fbqEvent = new Event('FbqLoad');
	document.dispatchEvent(fbqEvent);
});
