/* global document */

const openNavButton = document.querySelector('.js-nav-open');
const navigation = document.querySelector('.js-nav-primary');

if (openNavButton) {
	openNavButton.addEventListener('click', () => {
		if (navigation) {
			openNavButton.classList.toggle('is-open');
			navigation.classList.toggle('is-open');
		}
	});
}
