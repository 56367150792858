/* global document */

import Choices from	'choices.js';

const choiceType = document.querySelector('.js-types__select');

if (choiceType) {
	// eslint-disable-next-line no-new
	new Choices(choiceType, {
		allowHTML: true,
		removeItemButton: false,
		searchEnabled: false,
		shouldSort: false,
	});
}
