/* global document */

function getCookie(name) {
	function escape(s) { return s.replace(/([.*+?$(){}|\\])/g, '\\$1'); }
	const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));
	return match ? match[1] : null;
}

const showOffer = (e) => {
	const shouldShowExitIntent = !e.relatedTarget && e.clientY < 10;

	if (shouldShowExitIntent) {
		document.removeEventListener('mouseout', showOffer);
		document.querySelector('.js-exit-offer').classList.add('is-open');

		const date = new Date();
		date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));

		document.cookie = `plakatov_exit_offer=true; path=/; expires=${date.toUTCString()};`;
	}
};

const exitModal = document.querySelector('.js-exit-offer');

if (exitModal) {
	if (!getCookie('plakatov_exit_offer')) {
		setTimeout(() => {
			document.addEventListener('mouseout', showOffer);
		}, 5000);
		const exits = exitModal.querySelectorAll('[data-dismiss]');
		if (exits) {
			exits.forEach((e) => {
				e.addEventListener('click', (event) => {
					event.preventDefault();
					exitModal.classList.remove('is-open');
				});
			});
		}
	}
}
