/* global document */
const orderWrapper = document.querySelector('.js-order-wrapper');
const btnContinue = document.querySelector('.js-order-continue');

function switchVisible() {
	orderWrapper.classList.toggle('switched');
	orderWrapper.scrollIntoView({ behavior: 'smooth' });

	const previousBreadcrumb = document.querySelector('.overlay__bc-item-mobile');
	const breadcrumbLink = document.querySelector('.overlay__bc-item-mobile-link');
	const nextBreadcrumb = document.querySelector('.overlay__bc-item-mobile-last');
	previousBreadcrumb.classList.toggle('is-active');
	previousBreadcrumb.classList.add('overlay__bc-item-hidden');
	breadcrumbLink.classList.remove('overlay__bc-item-hidden');
	nextBreadcrumb.classList.toggle('is-active');
}

if (btnContinue) {
	btnContinue.addEventListener('click', switchVisible);
}
